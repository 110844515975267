<template>
    <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
        <h1 class="text-xl font-semibold leading-tight text-center tracking-wide text-gray-800 md:text-2xl">Forgot Password</h1>
        <h6 class="text-base font-medium leading-tight tracking-wide text-gray-800 md:text-base">
            One-time Password has been sent to your Email
            <span class="text-primary font-normal">
                {{ formData.email }}
            </span>
        </h6>
        <div class="space-y-6 md:space-y-8">
            <div>
                <label for="otp-input" class="block mb-2 text-sm font-medium text-gray-900">Enter One-time Password</label>
                <otp-input @on-complete="onComplete" />
            </div>
            <button :disabled="errors.formData.otp.$invalid" type="submit" class="otp w-full text-white-text bg-primary hover:bg-primary-focus focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-8 flex items-center justify-center" @click="onSubmit()">Confirm</button>
        </div>
    </div>
</template>

<script>
const OtpInput = () => import("@shared/components/otp-input");
import { getLoginInfo} from "./../../services";

export default {
    name: "forgot-password",
    props: {
        formData: {
            type: Object,
            default: () => ({}),
        },
        errors: {
            type: Object,
            default: () => {},
        },
        loadingStates: {
            type: Object,
            default: () => {},
        },
    },
    components: {
        OtpInput,
        
    },
    methods: {
        onComplete(value) {
            console.log("value :>> ", value);
            this.$emit("on-change", { type: "otp", value });
        },
        onSubmit() {
            console.log("CHECKED :>> ");
            this.$emit("on-submit", { component: "resetPassword" });
        },
    },
    mounted() {
        getLoginInfo();
    }
};
</script>

<style lang="scss" scoped></style>
